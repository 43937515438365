import { ProductObject } from '../actions/productActions';
import placeholderImage from '../images/placeholder.png';
import React, { Component } from 'react';
import xolaUrl from '../utils/xolaUrl';
import GiftImage from './GiftImage';

class ProductImage extends Component {
    getProductUri() {
        switch (this.props.product.object) {
            case ProductObject.EXPERIENCE:
                return 'experiences';
            case ProductObject.GIFT_OFFER:
                return this.props.product.baseGiftProduct ? null : 'giftOffers';
            default:
                return null;
        }
    }

    getImageUrl() {
        const uri = this.getProductUri();

        if (uri) {
            return xolaUrl(`api/${this.getProductUri()}/${this.props.product.id}/medias/default?size=medium`);
        }

        return placeholderImage;
    }

    render() {
        const { className, product, ...rest } = this.props;

        return (
            <div className={className} {...rest}>
                <div className="embed-responsive embed-responsive-1by1">
                    {product.baseGiftProduct ? (
                        <GiftImage className="embed-responsive-item" />
                    ) : (
                        <img
                            className="embed-responsive-item cover"
                            alt={product.name || 'Gift Card'}
                            src={this.getImageUrl()}
                            notranslate=""
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default ProductImage;
